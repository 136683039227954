<script lang="ts">
    import type { GroupData } from "$bindings/api/GroupData"
    import Image from "$components/Image.svelte"

    export let profile: GroupData
    export let detail: string | null = null
</script>

<div class="flex items-center gap-5 truncate">
    <div
        class="box-content aspect-square w-20 shrink-0 overflow-hidden border-r border-step-150 bg-step-050"
    >
        <Image image={profile.image} alt="Group Profile" size={128} />
    </div>

    <div class="flex shrink flex-col truncate">
        <p class="text-md truncate text-left font-medium">
            {profile.name}
        </p>
        {#if /*profile.shortname ||*/ detail}
            <p class="truncate text-left text-xs text-step-500">
                <!-- {#if profile.shortname}
                    #{profile.shortname},
                {/if} -->
                {#if detail}
                    {detail}
                {/if}
            </p>
        {/if}
    </div>
</div>
